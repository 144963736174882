:root {
  color-scheme: light dark;
}

html {
  --default-fonts-serif: "Segoe UI", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  --default-fonts-sans-serif: "Helvetica Neue", Helvetica, Roboto, "Segoe UI",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  --emoji-fonts: "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  --font-family-sans-serif: "Inter";
  --font-family-serif: "Inter";
  --font-family-monospace: monospace;

  text-rendering: optimizeLegibility;
  font-variant-ligatures: common-ligatures;
  font-feature-settings: "kern", "liga", "clig";
  font-kerning: normal;

  --text-block-width: 50rem;

  --accent-color2-60: hsla(164.3, 15%, 70%, 0.3);
  --accent-color2-20: hsla(146.1, 15%, 70%, 0.1);
}

@supports (font-variation-settings: normal) {
  html {
    --font-family-serif: "PublicSans var", PublicSans;
    --font-family-sans-serif: "Inter var", Inter;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --main-color: #c4cbbd;
    --accent-color2-60: hsla(164.7, 72.8%, 15.9%, 0.3);
    --accent-color2-20: hsla(146.2, 82.9%, 20.6%, 0.1);
  }

  html {
    --fg-color: #fff;
    --bg-color: #0c0a11;

    --accent-fg-color: #65dc98;
    --accent-bg-color: #222035;
    --accent-bg-color-90: #22203590;
    --accent-bg-color-offset: #3d3b53;
  }
}

@media (prefers-color-scheme: light) {
  html {
    --fg-color: #38392a;
    --bg-color: #fff8df;

    --alert-color: #a70f25;
    --accent-fg-color: #8fffdc;
    --accent-bg-color: #c4f5e6;
    --accent-bg-color-90: #c4f5e690;
    --accent-bg-color-offset: #9ce9d1;
  }
}
