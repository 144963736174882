html,
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

body {
  background-color: var(--bg-color);
  color: var(--fg-color);
  font-family: -apple-system, BlinkMacSystemFont, var(--font-family-sans-serif),
    var(--default-fonts-serif), var(--emoji-fonts);

  overflow: hidden;
  font-size: 1rem;
  line-height: 2;
  user-select: none;
}

/* https://codepen.io/tennowman/pen/ynrih */
.background-grid--blueprint {
  background-color: var(--bg-color);
  background-image: linear-gradient(
      var(--accent-color2-60) 2px,
      transparent 2px
    ),
    linear-gradient(90deg, var(--accent-color2-60) 2px, transparent 2px),
    linear-gradient(var(--accent-color2-20) 1px, transparent 1px),
    linear-gradient(90deg, var(--accent-color2-20) 1px, transparent 1px);
  background-size: 100px 100px, 100px 100px, 25px 25px, 25px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
}

.background-grid--dots {
  background: linear-gradient(90deg, var(--bg-color) 99px, transparent 1%)
      center,
    linear-gradient(var(--bg-color) 99px, transparent 1%) center,
    var(--accent-bg-color);
  background-size: 100px 100px;
}

/* https://codepen.io/leemark/pen/icHjI */
.background-grid--multiple-gradients {
  background-color: #000;
  background-image: repeating-linear-gradient(
      transparent,
      transparent 50px,
      rgba(0, 255, 255, 0.25) 50px,
      rgba(0, 255, 255, 0.25) 100px
    ),
    repeating-linear-gradient(
      90deg,
      rgba(0, 255, 255, 0.25),
      rgba(0, 255, 255, 0.25) 50px,
      transparent 50px,
      transparent 100px
    ),
    repeating-linear-gradient(
      135deg,
      transparent,
      transparent 4px,
      rgba(255, 255, 255, 0.1) 4px,
      rgba(255, 255, 255, 0.1) 8px
    ),
    repeating-linear-gradient(
      45deg,
      transparent,
      transparent 4px,
      rgba(255, 255, 255, 0.1) 4px,
      rgba(255, 255, 255, 0.1) 8px
    ),
    repeating-linear-gradient(
      transparent,
      transparent 20px,
      rgba(100, 250, 250, 0.2) 20px,
      rgba(100, 250, 250, 0.2) 21px,
      transparent 21px,
      transparent 29px,
      rgba(100, 250, 250, 0.2) 29px,
      rgba(100, 250, 250, 0.2) 30px,
      transparent 30px,
      transparent 50px
    ),
    repeating-linear-gradient(
      90deg,
      transparent,
      transparent 20px,
      rgba(100, 250, 250, 0.2) 20px,
      rgba(100, 250, 250, 0.2) 21px,
      transparent 21px,
      transparent 29px,
      rgba(100, 250, 250, 0.2) 29px,
      rgba(100, 250, 250, 0.2) 30px,
      transparent 30px,
      transparent 50px
    );
}

/* Additional grid ideas */
/* https://codepen.io/doytch/pen/VaWqBZ */
/* https://codepen.io/edmundojr/pen/xOYJGw */

main {
  width: 100%;
  height: 100%;
}

canvas {
  display: block;
  shape-rendering: crispEdges;
}

a {
  color: inherit;
  display: inline;
}

pre {
  overflow: auto;
  pointer-events: auto;
  user-select: text;
  line-height: 1.2;
  white-space: nowrap;
}

code {
  padding-inline: 5px;
}

.canvas-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

button {
  pointer-events: auto;
  font: inherit;
}

@keyframes overlayIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

input {
  background-color: var(--bg-color);
  color: var(--fg-color);
  border-width: 2pt;
  border-color: var(--accent-fg-color);
}

.resizing canvas {
  filter: blur(10px);
}

#app {
  height: 100%;
  width: 100%;
}

::selection {
  background: gold;
  text-shadow: 0 0 2px white;
}
@media (prefers-color-scheme: dark) {
  ::selection {
    background: #00617d;
    text-shadow: unset;
  }
}

/* canvas focus border */
#canvas1:focus {
  box-shadow: inset 0 0 1em 1em #ffd70050, inset 0 0 0.1em 0.3em #ffd700;
}

/* /1* Modal *1/ */
/* .modal-overlay { */
/* 	position: fixed; */
/* 	top: 0; */
/* 	left: 0; */
/* 	right: 0; */
/* 	bottom: 0; */
/* 	z-index: 256; */

/* 	height: 100%; */
/* 	width: 100%; */

/* 	animation: ease-in 96ms overlayIn; */
/* 	opacity: 1; */
/* 	background-color: var(--accent-color-80); */

/* 	display: grid; */
/* 	align-items: center; */
/* 	justify-content: center; */
/* } */

@media (prefers-reduced-motion: reduce) {
  .modal-overlay {
    animation: none;
  }
}

.icon-button {
  color: var(--fg-color);
}

.icon-button--background {
  stroke: var(--bg-color);
}

.canvas-container {
  position: absolute !important;
}

/** On landscape  */
@media (max-height: 100px) and (orientation: landscape) {
  #hiddenTextarea,
  .mobile-text-fix {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0.4em;
    background-color: var(--bg-color);
  }

  .mobile-text-fix .done {
    display: block;
  }
}

/** Tippy tooltip */
.tippy-box {
  background-color: var(--accent-bg-color);
}

.tippy-arrow {
  color: var(--accent-bg-color);
}

.tippy-content {
  font-family: -apple-system, BlinkMacSystemFont, var(--font-family-sans-serif),
    var(--default-fonts-serif), var(--emoji-fonts);
  color: var(--fg-color);
  font-size: inherit;
}

.tippy-tooltip {
  background-color: var(--accent-bg-color);
}

.tippy-tooltip[data-placement^="top"].tippy-arrow {
  border-top-color: var(--accent-bg-color);
}
.tippy-tooltip[data-placement^="bottom"].tippy-arrow {
  border-bottom-color: var(--accent-bg-color);
}
.tippy-tooltip[data-placement^="left"].tippy-arrow {
  border-left-color: var(--accent-bg-color);
}
.tippy-tooltip[data-placement^="right"].tippy-arrow {
  border-right-color: var(--accent-bg-color);
}
