.tippy-tooltip.floating-theme {
  background-color: transparent;
  color: black;
}

.tippy-tooltip.floating-theme[x-placement^="top"] .tippy-arrow {
  border: 8px solid transparent;
}

.tippy-tooltip.floating-theme[x-placement^="bottom"] .tippy-arrow {
  border: 8px solid transparent;
}

.tippy-tooltip.floating-theme[x-placement^="left"] .tippy-arrow {
  border: 8px solid transparent;
}

.tippy-tooltip.floating-theme[x-placement^="right"] .tippy-arrow {
  border: 8px solid transparent;
}

.tippy-tooltip.floating-theme .tippy-backdrop {
  background-color: transparent;
  border-radius: none;
  border: none;
}

.tippy-tooltip.floating-theme .tippy-roundarrow {
  fill: transparent;
}

.tippy-tooltip.floating-theme[data-animatefill] {
  background-color: transparent;
}
